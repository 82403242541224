@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';
@import "./base/custom.scss";
@import "./base/print";
// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
@import "node_modules/react-date-range/src/styles.scss";
@import "node_modules/react-date-range/src/theme/default.scss";

// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';

.form-error{
    color:rgb(218, 49, 49)
}
