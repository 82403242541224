.modal-dialog-centered {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fff;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
//upload start
.file-wrap {
  width: 300px;
  padding: 20px;
  text-align: center;
  border: 2px dashed #ddd;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
}

.file-wrap.dropped {
  border-color: #007bff;
}

.after-file-upload {
  margin-top: 10px;
}

.animated-line path {
  stroke: #007bff;
  stroke-width: 4;
  fill: none;
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  animation: draw-line 2s linear forwards;
}

@keyframes draw-line {
  to {
    stroke-dashoffset: 0;
  }
}

.tick-line .check {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: draw-tick 1s ease-in-out forwards;
}

@keyframes draw-tick {
  to {
    stroke-dashoffset: 0;
  }
}

.text-uploaded {
  display: block;
  margin-top: 10px;
  font-size: 18px;
  color: #07da38;
}
//codepin upload start
.ignore-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-upload {
  max-width: 450px;
  padding: 50px;
  width: 100%;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.file-wrap {
  border: 2px dashed #F13218;
  border-radius: 12px;
  position: relative;
  transition: all 0.5s ease;
  transform: scale(1);
  cursor: pointer;
}

.file-wrap.dragging {
  transform: scale(1.1);
}

.file-wrap input[type="file"] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.custom-upload-design {
  padding: 50px 30px;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.upload-text {
  font-size: 24px;
  text-align: center;
}

.upload-text span {
  color: #F13218;
  font-weight: bold;
}

.upload-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F13218;
  margin-bottom: 15px;
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}

.upload-icon img {
  max-width: 20px;
}

.dropped .upload-text {
  display: none;
}

.dropped .upload-icon {
  transform: scale(2.8);
  opacity: 1;
  transition: all 0.5s ease-in-out;
  animation: bounce 1s 0.5s;
}

.animate .upload-icon {
  opacity: 0;
}

.dropped .upload-icon img {
  opacity: 0;
}

.file-wrap.dropped {
  border-color: transparent;
}

.after-file-upload {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animated-line {
  stroke: #07da38;
  stroke-width: 20;
  fill: transparent;
  animation: progressAnimation 2s linear;
  position: relative;
  top: -6px;
  z-index: 1;
}

.animated-line-2 {
  stroke: #ffebe8;
  stroke-width: 20;
  fill: transparent;
  position: absolute;
  margin: auto;
  top: 19px;
}

@keyframes progressAnimation {
  0% {
    stroke-dasharray: 0, 565;
    stroke: #f13218;
  }
  100% {
    stroke-dasharray: 565;
    stroke: #07da38;
  }
}

.after-file-upload > div {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100px;
  margin: 0 auto;
}

.uploaded .after-file-upload {
  transform: scale(0.5);
  top: -10px;
  transition: all 0.5s ease;
}

.tick-line {
  width: 100px;
  height: 100px;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.path.check {
  stroke-dashoffset: -100;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

.text-uploaded {
  position: absolute;
  bottom: -30px;
  font-size: 32px;
  left: 0;
  right: 0;
  text-align: center;
  color: #07da38;
  font-weight: bold;
}

//codepin upload end
//upload ends
.dark-layout{
  .table-light {
    background-color: #161d30;
    &:hover {
      background-color: #283046;
    }
  }
  .apexcharts-legend-text {
    color: #eee !important;
  }
  .sc-aXZVg.bcNoJr {
    input {
       background-color: #161d30;
      height: 34px;
      color: #fff;
      border-color: #283046;
      &::placeholder {
        color: #3b4253;
      }
    }
  }
  .MuiTableCell-root {
    border-color: #161e31;
  }
  .tss-ylo811-MUIDataTableSelectCell-headerCell {
    background-color: #283046;
  }
  .in-dark-white {
    color: #d1d3d4 !important;
  }
  .date-holder {
    border-color: #3b4253;
  }
  .tss-11quiee-MUIDataTable-paper {
    background-color: #283046;
  }
  thead {
    background-color: #283046;
  }
  .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
    background-color: #283046;
  }
  th, td {
    color:#fff
  }

}
.dark-layout .tss-11quiee-MUIDataTable-paper .MuiTableFooter-root td, .dark-layout .tss-11quiee-MUIDataTable-paper .MuiTablePagination-root {
  color: #fff;
}
.dark-layout .tss-11quiee-MUIDataTable-paper .MuiButtonBase-root, .dark-layout .tss-11quiee-MUIDataTable-paper .MuiTableHead-root th, .dark-layout .tss-11quiee-MUIDataTable-paper th {
  color: #fff
}
.sales-list {
  position: relative;
  small {
    position: absolute;
    right: 10px;
    top:10px
  }
}
.sc-aXZVg.bcNoJr {
  input {
    height: 34px;
  }
}
.in-dark-white {
  color: #333 !important;
}
.make-scroll {
  max-height: 400px;
  overflow: scroll;
}
.text-12px {
  font-size: 12px;
}
.mt-30px {
  margin-top: 30px;
}
.mt-10px {
  margin-top: 10px;
}
.mt-5px {
  margin-top: 5px;
}
.mb-5px {
  margin-bottom: 5px;
}
.mt-7px {
  margin-top: 7px;
}
.mt-2px {
  margin-top: 2px;
}
.mt-10 {
  margin-top: 150px;
}
.ml-5px {
  margin-left: 5px;
}
.mt-neg-5px {
  margin-top: -5px;
}
.pl-10px {
  padding-left: 10px;
}
.pr-10px {
 padding-right: 10px;
}
.pb-10px {
  padding-bottom: 10px;
}
.pt-10px {
  padding-top: 10px;
}
.p-10px {
  padding: 10px;
}
.pl-5px {
  padding-left: 5px;
}
.pr-5px {
  padding-right: 5px;
}
.pb-5px {
  padding-bottom: 5px;
}
.pt-5px {
  padding-top: 5px;
}
.pt-7px {
  padding-top: 7px;
}
.p-5px {
  padding: 5px;
}
.bt-1 {
  border-top: 1px solid #f1f1f2;
}
.bt-2 {
  border-top: 2px solid #f1f1f2;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.w-300px {
  width: 300px;
}
.text-black {
  color: #000 !important;
}
.most-sold {
  .progress {
    height: 1.5em;
  }
}
.zindex-6 {
  z-index: 6 !important;
}
.li-hover {
  &:hover {
    background-color: #f8f8f8;
    padding-left: 10px;
  }
}
.mui-footer-text {
  font-size: 1.2em;
  font-weight: bold;
}
.MuiTableFooter-root {
  position: sticky;
}
.height-110 {
  min-height: 110px;
  img {
    margin-top: 10px;
    margin-bottom:10px;
    height: 110px;
    vertical-align: middle;
    width:100%;
    object-fit: contain;
  }
}
.image-slot {
  padding:10px;
  text-align: center;
  width: 100%;
  border: 1px dashed #d1d3d4;
  margin-bottom: 10px;
  .uppy-Root {
    border: 1px dashed #d1d3d4;
  }
}
.sc-bczRLJ, .dNzNHH {
 input {
   width: 100% !important;
   padding: 10px !important;
 }
  svg {
    display: none;
  }
}
$table-color: #84817a;
$table-color-strong: #5E5C57;
$table-text-color: #fff;
$table-input-background-color: transparent;
//
//$table-color: #fff;
//$table-color-strong: #fff;
//$table-text-color: #000;
//$table-input-background-color: #f1f2f6;
.fast-card {
  background-color: $table-color ;
  h4 {
    color: $table-text-color;
  }
  input {
    border-color: $table-color-strong;
    //color: $table-text-color;
    background-color: #f1f2f6;
    //&::placeholder {
    //  color: $table-text-color;
    //}
  }
}
.table-fast {
  thead {
    background-color: $table-color !important;
    tr {
      td {

      }
    }
    tr {
      th {
        color: $table-text-color;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        background-color: $table-color  !important;
      }
    }
  }
 tfoot {
   background-color: rgba(106, 176, 76,.3);
   tr {
     td {
       color: rgba(106, 176, 76,1.0);
     }
   }
 }
}
.first-left-table {
  thead {
    background-color: #f1f1f2;
  }
  th, td {
    div {
      text-align: right;
      display: block !important;
    }

    &:first-child div {
      text-align: left !important;
    }
  }
  td {
    text-align: right;

    &:first-child {
      text-align: left;
      font-weight: bold;
    }
  }
}
.main-menu .navbar-header .navbar-brand {
  margin-top: .7rem;
}
.main-menu .navbar-header .navbar-brand .brand-text {
  color: #6e6b7b;
}
.nav .modern-nav-toggle {
  padding: 0;
  margin: 1.571rem 0;
  margin-top: 1.1rem;
}
.fcm-img {
  width: 200px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 20px;
}
.map-holder {
  position: relative;
  .places-input {
    position: absolute;
    top: 10px;
    right: 60px;
    padding: 10px;
    width: 350px;
  }
}
.pac-container {
  z-index: 9999;
}
.color-white {
  color: #fff;
}
.pos-cart {
  .pos-cart-card {
    height: 100vh;
    overflow: auto;
  }
  position: relative;
  .checkout-container {
    padding: 10px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: #7367F0;
  }
  .checkout-container-disable {
    padding: 10px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: #c6c3e6;
  }
}
.broder-left-radios-0 {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.opacity-50 {
  opacity: .5;
}
.dummy {
  margin-top: 80px;
}
.pdummy {
 padding-top: 50px;
}
.plusminus-value {
  min-width: 25px;
  text-align: center;
  display: inline-block;
}
.pos-cart-item {
  margin-left: 10px;
  margin-right: 10px;
}
.text-fix {
  line-height: 1.5em;
  letter-spacing: .05em;
}
.text-black {
  color: #000;
}
.box {
  height: 14px;
  width: 100%;
  border-radius: 10px;
}
.pos-header {
  position: relative;
  .btn {
    padding: 20px;
    width: 100%;
    //border-radius: 4px 0px 0px 4px;
  }
  .pos-head-sticky {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    button {
      border-radius: 0px;
    }
  }
  .pos-user-item {
    margin-top: 7px;
  }
  .deliver-to {
    margin-top: 7px;
    .deliver-icon {
      margin-left: 20px;
      margin-right: 10px;
      margin-top: 5px;
    }
    h5 {
      height: 1em;
      overflow: hidden;
      &:after {
        content: "...";
      }
    }
  }
  .active-order {
    background-color: #6ab04c;
    width: 100%;
    //border-radius: 0px 4px 4px 0px;
    .active-order-img {
      float: left;
      color: #fff;
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .active-order-text {
      float: left;
    }
    p {
      opacity: .5;
      padding-top: 5px;
      color: #fff;
      margin-bottom: 0px;
    }
    h5 {
      padding-bottom: 10px;
      margin-bottom: 0px;
      color: #fff;
    }
  }
}
.pos-cart {
  //background-color: #fff;
  height: 100vh;
}
.input-hover {
  position: relative;
  .input-hover-new {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
.email-user-list .email-media-list .media:nth-child(even) {
  background-color: transparent !important;
}
.staff-info-holder {
  .staff-info-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: -70px auto 0 auto;
    overflow: hidden;
    img {

      width: 100%;
      height: auto;
    }
  }
}
.card.active {
  border: 1px solid rgba(115, 103, 240, 1);
  box-shadow: 0px 0px 10px rgba(106, 176, 76,1.0);
  .mystars {
    h4 {
      color: rgba(106, 176, 76,1.0);
      font-weight: 800;
    }
  }
}
.table {
  td {
    font-weight: 600;
  }
}
.card.filter-card {
  border: 1px solid transparent;
}
.emoji-holder {
  width: 128px;
  height: auto;
  margin: 0 auto;
}
.table-responsive {
  overflow-y: inherit !important;
}
.item-img-no-height {
  min-height: auto !important;
}
.ecommerce-application .grid-view .ecommerce-card .item-img {
  min-height: auto !important;
}
.ecommerce-application .app-ecommerce-details .item-features {
  padding: 5px !important;
}
.mystars {
  cursor: pointer;
  .emoji-holder {
    width: 80px;
  }

  .star-ratings {
    width: auto;
    .star-container {
      padding-right: 0px !important;
      margin-right: -8px;
    }
  }
}
.mystars.active{
  background-color: rgb(230, 219, 243);
  cursor: pointer;
}


.print-order {
  background-color: #FFF;
  //padding: 20px;
  width: auto;
  color: #000;
  .ins {
    background-color: #000;
    color: #fff;
    padding: 5px 10px !important;
    margin: 0 15px 0 0;
  }

  .bill {

    width: 100%;
    margin: 0px;
  }

  .bill .note {
    padding-top: 50px;
  }

  .bill h1 {
    text-align: center;
    font-size: 25px;
    padding: 0px;
    margin: 0px;
  }

  .bill p {
    padding-top: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
  }

  .bill table {

    width: 100%;
    height: auto;
    padding-top: 0px;
    border: 1px;
  }

  .bill table thead {
    border-bottom: 1px dashed #000;
  }

  .bill table tr {
    padding-bottom: 10px;
  }

  .bill table th {
    text-align: left;
    font-weight: bold;
  }

  .bill table td {
    font-size: normal;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .bill table td:last-child {
    text-align: right;
  }

  .bill table tfoot {
    border-top: 1px dashed #000;
  }

  .bill .center {
    text-align: center;
    padding-top: 5px;
  }

  .dot-border {
    padding: 0;
    border-top: 1px dashed #000;
    height: 1px;
  }

  .note {
    padding-bottom: 40px;
  }

  .space {
    height: 20px;
  }

  .center {
    text-align: center;
  }

  .border-top-dotted {
    border-top: 1px dashed #000;
    display: table;
    width: auto;
  }

  .border-bottom-dotted {
    border-bottom: 1px dashed #000;
    display: table;
    width: auto;
  }

  .space {
    height: 40px;
  }

  .big {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .small-space {
    height: 20px;
  }

  .big-font {
    font-style: 10px;
  }
}
.bill {

  width: 100%;
  margin: 0px;
}

.bill .note {
  padding-top: 50px;
}

.bill h1 {
  text-align: center;
  font-size: 25px;
  padding: 0px;
  margin: 0px;
}

.bill p {
  padding-top: 0px;
  margin-top: 0px;
  padding-bottom: 0px;
}

.bill table {

  width: 100%;
  height: auto;
  padding-top: 0px;
  border: 1px;
}

.bill table thead {
  border-bottom: 1px dashed #000;
}

.bill table tr {
  padding-bottom: 10px;
}

.bill table th {
  text-align: left;
  font-weight: bold;
}

.bill table td {
  font-size: normal;
  padding-bottom: 0px;
  padding-top: 0px;
}

.bill table td:last-child {
  text-align: right;
}

.bill table tfoot {
  border-top: 1px dashed #000;
}

.bill .center {
  text-align: center;
  padding-top: 5px;
}

.dot-border {
  padding: 0;
  border-top: 1px dashed #000;
  height: 1px;
}

.note {
  padding-bottom: 40px;
}

.space {
  height: 20px;
}

.center {
  text-align: center;
}

.border-top-dotted {
  border-top: 1px dashed #000;
  display: table;
  width: auto;
}

.border-bottom-dotted {
  border-bottom: 1px dashed #000;
  display: table;
  width: auto;
}

.space {
  height: 40px;
}

.big {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.small-space {
  height: 20px;
}

.big-font {
  font-style: 10px;
}
.force-shadow.card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, .1) !important;
}
@media print {
  body {
    width: 100vw;
    background-color: transparent !important;
    font-family: monospace !important;
  }
  p,h1,h2,h3,h4,h5,table,td,th,div,strong,span {
    font-family: Arial, "Helvetica Neue" !important;
    font-size: 100%;
  }
  .big-bark {
    font-size: 30px;
  }
  .print-order .bill .center {
    padding-top: 0px;
  }
  .page-break	{ display: block; page-break-before: always; }
  .print-order {
    width: 410px;
  }
}
.custom-no-results {
  img {
    width: 360px;
    height: auto;
  }
}
.text-dim {
  color: #d1d3d4;
}
.zindex-5 {
  z-index: 5 !important;
}
.email-media-list {
  .media:nth-child(even) {
    background-color: #f5f6fa !important;
  }

}
.pagination {
  margin-top: 10px;
  margin-bottom: 10px;
   li {
     font-weight: bold;
     &.disabled {
      a {
        color: #d1d3d4 !important;
      }
     }
     .active {
       a {
         background-color: #7367f0;
       }
     }
     a {
       width: 30px;
       height: 30px;
       display: inherit;
       color: #7367f0;
     }
   }
}
.items-per-page {
  opacity: .4;
}
.w100px {
  min-width: 100px;
}
.w200px {
  min-width: 200px;
}
.w250px {
  min-width: 250px;
}
.flex-container {
  display: flex;
  flex-wrap: nowrap;
}

.flex-container > div {
  width: auto;
}
.cursor-pointer {
  cursor: pointer;
}
.light-Walkin {
  background-color: rgba(238, 90, 36, .2) ;
  color: rgba(238, 90, 36, 1);
}
.light-Android {
  background-color: rgba(72, 219, 251,.2) ;
  color: rgba(72, 219, 251,1.0);
}
.light-Tipplr {
  background-color: rgba(243, 104, 224,.2) ;
  color: rgba(243, 104, 224,1.0);
}
.light-Amazon {
  background-color: rgba(249, 202, 36,.2) ;
  color: rgba(249, 202, 36,1.0);
}
.light-Dunzo {
  background-color: rgba(72, 52, 212,.2) ;
  color: rgba(72, 52, 212,1.0);
}
.light-WEB {
  background-color: rgba(238, 90, 36, .2) ;
  color: rgba(238, 90, 36, 1);
}
.light-Zomato {
  background-color: rgba(203, 32, 45, .2) ;
  color: rgba(203, 32, 45, 1) ;
}
.light-Swiggy {
  background-color: rgba(240, 147, 43,.2) ;
  color: rgba(240, 147, 43,1.0) ;
}
.light-Call {
  background-color: rgba(106, 176, 76,.2) ;
  color: rgba(106, 176, 76,1.0) ;
}

.outline-Walkin {
  background-color: transparent;
  border:1px solid rgba(238, 90, 36, .7) ;
  color: rgba(238, 90, 36, 1);
}
.outline-Android {
  background-color: transparent;
  border:1px solid rgba(72, 219, 251,.7) ;
  color: rgba(72, 219, 251,1.0);
}
.outline-Tipplr {
  background-color: transparent;
  border:1px solid rgba(243, 104, 224,.7) ;
  color: rgba(243, 104, 224,1.0);
}
.outline-Amazon {
  background-color: transparent;
  border:1px solid rgba(249, 202, 36,.7) ;
  color: rgba(249, 202, 36,1.0);
}
.outline-Dunzo {
  background-color: transparent;
  border:1px solid rgba(72, 52, 212,.7) ;
  color: rgba(72, 52, 212,1.0);
}
.outline-WEB {
  background-color: transparent;
  border:1px solid rgba(238, 90, 36, .7) ;
  color: rgba(238, 90, 36, 1);
}
.outline-Zomato {
  background-color: transparent;
  border:1px solid rgba(203, 32, 45, .7) ;
  color: rgba(203, 32, 45, 1) ;
}
.outline-Swiggy {
  background-color: transparent;
  border:1px solid rgba(240, 147, 43,.7) ;
  color: rgba(240, 147, 43,1.0) ;
}
.outline-Call {
  background-color: transparent;
  border:1px solid rgba(106, 176, 76,.7) ;
  color: rgba(106, 176, 76,1.0) ;
}

.star-ratings {
  width: 160px;
}
.line-height-line {
  height: 1.3em;
  overflow: hidden;
}
.category-holder {
  .category-img {
    //width: ;
  }
}
.source_holder {
  .source_header  {
    .source_img {
      width: 40px;
      height: 40px;
      float: left;
    }
    .source_text {
      height: auto;
      float: left;
    }
  }
}
.order_source_table {
    width: 100%;
    td {
      width: 50%;
      //border-bottom: 1px solid #f1f1f2;
    }
}
.date-holder {
  border: 1px solid #ededed;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  .date-date {
    padding-left: 5px;
    width: 40%;
    float: left;
    color: #6e6b7b;
  }
  .date-date-arrow {
    width: 20%;
    float: left;
    color: #6e6b7b;
  }
}
.model-dateRange {
  max-width: 600px;
}
.fcm-header {
  margin-top: 20px;
  margin-bottom: 20px;
  .fcm-text {
    padding-top: 50px;
  }
}
.dropzone {
  button {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    border: 1px dashed rgb(115, 103, 240);
    padding: 20px;
    background-color: rgb(248, 248, 248);
  }
}
.map-holder {
  width: 100%;
  height: 500px;
  background-color: #f1f1f3;
  &.map-holder-popup {
    height: 350px;
    border-top-left-radius: 4px;
    border-top-right-radius: 40px;
    overflow: hidden;
  }
}
.no-items-cart {
  width: 200px;
  margin: 0 auto;
}
.cart-side {
  height: 100vh;
  position: fixed;
  top: 0;
  right: -15px;
}
.popup-branch-address {
  background-color: #fcfcfc;
}
.coupon-holder {
  .coupon-header {
    background-color: #00cfe8;
    width: 100%;
    display: block;
    padding-top: 70px;
    height: 150px;
    text-align: center;
    h1 {
      color: #fff;
    }
  }
}
.serving-area {
  width: 100%;
  height: 500px;
}
.billing-page {
  .billing-header {
    padding:20px;
    //background-color: #ecf0f1;
    p {
      margin-bottom: 0px;
    }
  }
}
.my-ul {
  margin-left: 0px;
  padding-left: 0px;
  list-style: none;
  li {
    padding-left: 0px;
    margin-bottom: 10px;
  }
  .media {
    padding:0px;
  }
}
.billing-products {
  padding: 10px;
  background-color: #fff;
  height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;

}
.avatar-group.avatar-sm img {
  width: 24px;
  height: 24px;
}
.bb-dashed {
  border-bottom: 1px dashed;
}
.user-holder {
  .user-img {
    float: left;
    img {
      width: 46px;
      height: 46px;
      border-radius: 100%;
    }
  }
  .user-text {
    margin-left: 10px;
    float: left;
    h4 {
      margin-bottom: 0px;
      margin-top: 5px;
      font-weight: bold;
    }
  }

}
.billing-input-holder {
  width: 300px;
  height: auto;
  margin: 100px auto;
}
.bs-stepper.wizard-modern .bs-stepper-content {
  background-color: transparent !important;
  box-shadow: none;
}
.billing-active-order {

  .billing-active-header {
    background-color: #f5f6fa;
    padding: 10px 20px;
    h5 {
      font-weight: bold;
      margin-bottom: 0px;
    }
    p {
      text-align: right;
      margin-bottom: 0px;
    }
  }

}
.mdt-aside {
  .mtd-gross {
    background-color: #8bc34a;
    color: #fff;
    h4 {
      color: #fff;
    }
    padding: 10px;
  }
  .mtd-discount {
    background-color: #03a9f4;
    color: #fff;
    h4 {
      color: #fff;
    }
    padding: 10px;
  }
  .mtd-net {
    background-color: #26a69a;
    color: #fff;
    h4 {
      color: #fff;
    }
    padding: 10px;
  }
  .mtd-orders {
    background-color: #25476a;
    color: #fff;
    h4 {
      color: #fff;
    }
    padding: 10px;
  }
  .mtd-abv {
    background-color: #ffb300;
    color: #fff;
    h4 {
      color: #fff;
    }
    padding: 10px;
  }
}
.dashboard-bar {
  width: 100%;
}
.b-gross-color {
  border: 1px solid #81D61F;
  h3 {
    color: #81D61F;
  }
}
.b-net-color {
  border: 1px solid #05B3A2;
  h3 {
    color: #05B3A2;
  }
}
.b-orders-color {
  border: 1px solid #17426D;
  h3 {
    color: #17426D;
  }
}
.b-abv-color {
  border: 1px solid #F0B402;
  h3 {
    color: #F0B402;
  }
}
.dashboard-graph-holder {
  .sales-list {
    padding: 10px;
    margin-bottom: 20px;
    p {
      margin-bottom: 0px;
    }
    h3 {
      margin-bottom: 0px;
      font-weight: bold;
    }
  }
}
.mtd-shimmer-loader {

  .mtd-shimmer-block {
    border: 1px solid rgba(236, 240, 241,1);
    padding: 18px;
    margin-bottom: 20px;
    .shimmer-title--secondary {
      margin-bottom: 0px;
    }
    .shimmer-title {
      margin-bottom: 0px;
    }
  }
}
.dash-graph {
  width: 100%;
}
.dashboard-graph-title  {
  h5 {
    margin-bottom: 0px;
    font-weight: bold;
    text-transform: uppercase;
  }
  p {
    font-size: .9em;
  }
}
.shimmer-table-avatar {
  .shimmer-avatar {
    margin-top: 16px;
    margin-bottom: 0px;
  }
  .shimmer-title--secondary {
    margin-bottom: 0px;
  }
}
.thead-shimmer {
  .shimmer-title--secondary {
    margin-bottom: 0px;
  }
}
.product-loader {
  .shimmer-text {
    margin-bottom: 0px;
  }
}
.branch-status-product-check {
  text-align: right;
}
.grid-view {
  margin: 15px;
}
.staff-detail {
  text-align: left;
  ul {
    padding-left: 0px;
    list-style: none;
    li {

    }
  }
}
.table-clean {
  tr {
    background-color: transparent !important;
  }
}
@media (max-width: 575.98px) {
  .mobile-table {
    th {
      //display: none;
      display: block;
    }

      td {
        display: block;
        width: 100%;
      }

  }
  .xs-mb-2 {
    margin-bottom: 2rem;
  }
  .email-application .content-area-wrapper .email-user-list .email-media-list li .mail-details .mail-meta-item {
    position: relative !important;
    top: unset !important;
    right: unset !important;
    width: 100% !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .price-mobile {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .mobile-center {
    text-align: center !important;
  }
  .mobile-timeline {
    padding: 10px;
    .card-header {
      .card-title {
        text-align: center !important;
      }
    }

  }
  .mobile-feedback {
    border-bottom: 1px solid #d1d3d4;
    margin-bottom: 10px;
    padding-bottom: 10px;
    .btn-icon {
      margin-top: 10px;
    }
  }
  .action-mobile {
    text-align: left !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .modal-responsive {
    .rdrDateRangePickerWrapper {
      display: block;
    }
  }
  .input-holder {
    margin-bottom: 1rem;
  }
  .branch-status-product-check {
    text-align: center;
    margin-bottom: 20px;
  }
  .category-holder {
    p {
      height: 3em;
      overflow: hidden;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { }
