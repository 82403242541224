.print-order {
  background-color: #FFF;
  //padding: 20px;
  width: auto;
  color: #000;
  .ins {
    background-color: #000;
    color: #fff;
    padding: 5px 10px !important;
    margin: 0 15px 0 0;
  }

  .bill {

    width: 100%;
    margin: 0px;
  }

  .bill .note {
    padding-top: 50px;
  }

  .bill h1 {
    text-align: center;
    font-size: 25px;
    padding: 0px;
    margin: 0px;
  }

  .bill p {
    padding-top: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
  }

  .bill table {

    width: 100%;
    height: auto;
    padding-top: 0px;
    border: 1px;
  }

  .bill table thead {
    border-bottom: 1px dashed #000;
  }

  .bill table tr {
    padding-bottom: 10px;
  }

  .bill table th {
    text-align: left;
    font-weight: bold;
  }

  .bill table td {
    font-size: normal;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .bill table td:last-child {
    text-align: right;
  }

  .bill table tfoot {
    border-top: 1px dashed #000;
  }

  .bill .center {
    text-align: center;
    padding-top: 5px;
  }

  .dot-border {
    padding: 0;
    border-top: 1px dashed #000;
    height: 1px;
  }

  .note {
    padding-bottom: 40px;
  }

  .space {
    height: 20px;
  }

  .center {
    text-align: center;
  }

  .border-top-dotted {
    border-top: 1px dashed #000;
    display: table;
    width: auto;
  }

  .border-bottom-dotted {
    border-bottom: 1px dashed #000;
    display: table;
    width: auto;
  }

  .space {
    height: 40px;
  }

  .big {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .small-space {
    height: 20px;
  }

  .big-font {
    font-style: 10px;
  }
}
@media print {
  body {
    width: 100vw;
    background-color: transparent !important;
    font-family: monospace !important;
  }
  p,h1,h2,h3,h4,h5,table,td,th,div,strong,span {
    font-family: Arial, "Helvetica Neue" !important;
    font-size: 100%;
  }
  .big-bark {
    font-size: 30px;
  }
  .print-order .bill .center {
    padding-top: 0px;
  }
  .page-break	{ display: block; page-break-before: always; }
  .print-order {
    width: 410px;
  }
}
